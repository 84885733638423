import React, { useState } from 'react'
import Slider from '../Common/Slider'
import { Link } from 'react-router-dom'

const Home = () => {
	const [isOpen, setIsOpen] = useState(true);

	const openPopup = () => {
	  setIsOpen(true);
	};
  
	const closePopup = () => {
	  setIsOpen(false);
	};

	

  return (
    <>
        <Slider />

        <section className="facility-box"  style={{backgroundImage:'url("img/bg/bg4.png")'}}>
		<div className="container mt-5 py-5">			
			<div className="row mb-4">
				<div className="col-md-4">	
					<div className="text-center sec_top">
						<p className="icon-thumb"><Link to={'/Admission'}><img src="img/01.png" alt='' /></Link></p>
						<p className="small-head"><Link to={'/Admission'} className='text-dark'>Admission</Link></p>						
					</div>
				</div>
				<div className="col-md-4">	
					<div className="text-center sec_top">
						<p className="icon-thumb"><Link to={'/'}><img src="img/02.png" alt='' /></Link></p>
						<p className="small-head"><Link to={'/'} className='text-dark'>Student’s login</Link></p>						
					</div>
				</div>
				<div className="col-md-4">	
					<div className="text-center sec_top">
						<p className="icon-thumb"><Link to={'https://erp.aktu.ac.in/'} target='blank'><img src="img/03.png" alt='' /></Link></p>
						<p className="small-head"><Link to={'https://erp.aktu.ac.in/'} target='blank' className='text-dark'>Examination</Link></p>						
					</div>
				</div>
			
				<div className="col-md-4">	
					<div className="text-center sec_top">
						<p className="icon-thumb"><Link to={'https://erp.aktu.ac.in/'} target='blank'><img src="img/04.png"  alt='' /></Link></p>
						<p className="small-head"><Link to={'https://erp.aktu.ac.in/'} target='blank' className='text-dark'>Teacher's login</Link></p>						
					</div>
				</div>
				<div className="col-md-4">	
					<div className="text-center sec_top">
						<p className="icon-thumb"><Link to={'https://erp.aktu.ac.in/WebPages/OneView/OneView.aspx'} target='blank'><img src="img/05.png" alt='' /></Link></p>
						<p className="small-head"><Link to={'https://erp.aktu.ac.in/WebPages/OneView/OneView.aspx'} target='blank' className='text-dark'>Results</Link></p>						
					</div>
				</div>
				<div className="col-md-4">	
					<div className="text-center sec_top">
						<p className="icon-thumb"><Link to={'/'}><img src="img/04.png" alt='' /></Link></p>
						<p className="small-head"><Link to={'/StudentOnlineServices'} className='text-dark'>Student's Online Services</Link></p>						
					</div>
				</div>
			</div>
		</div>
	</section>


	<section className="facility-box bg-grey mt-4">
		<div className="container">
			<div className="row">
				<div className="col-12 center-col margin-50px-bottom sm-margin-40px-bottom text-center">
					<h3 className="font-weight-500 font-size32 md-font-size28 sm-font-size26 xs-font-size24 section-title">Latest Updates</h3>
					<div className="title-border"><span className="lg"></span><span className="md"></span><span className="sm"></span></div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-4">
					<div className="news-bg-column news-bg-01">
						<p className="news-head">Facebook</p>
						<iframe className="fac_ifrma" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmgip391%2F%3Fref%3Dpy_c&tabs=timeline&width=340&height=400px&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" ></iframe>
					</div>
				</div>
				<div className="col-md-8">
					<div className="news-bg-column news-bg-02">
						<p className="news-head">Notice</p>
						<div class="marquee_content">
							<div className='marquee'>
								<ul className='p-2 list-style5'>
								<li>								
									<Link to={'/img/pdf/notice1.pdf'} target='blank' className='text-dark'> 
										<p className='mb-1'>Guest Lecture on "Future Prospects for Pharmacy Graduates" dated 1st May 2024. <span className='blink'>New</span></p>
									</Link>								
								</li>
								<li>								
									<Link to={'/img/pdf/notice2.pdf'} target='blank' className='text-dark'> 
										<p className='mb-1'>Notice for Special Carry Paper Examination December 2023. <span className='blink'>New</span></p>
									</Link>								
								</li>
								<li>								
									<Link to={'/img/pdf/notice3.pdf'} target='blank' className='text-dark'> 
										<p className='mb-1'>Notice for Nam Navami dated 17th April 2024.</p>
									</Link>								
								</li>
								<li>								
									<Link to={'/img/pdf/notice4.pdf'} target='blank' className='text-dark'> 
										<p className='mb-1'>Time Table for B. Pharm IInd Semester (Session 2023-24).</p>
									</Link>								
								</li>
								<li>								
									<Link to={'/img/pdf/notice5.pdf'} target='blank' className='text-dark'> 
										<p className='mb-1'>Time Table for B. Pharm VIth Semester (Session 2023-24).</p>
									</Link>								
								</li>
								<li>								
									<Link to={'/img/pdf/notice6.pdf'} target='blank' className='text-dark'> 
										<p className='mb-1'>Time Table for B. Pharm IVth Semester (Session 2023-24).</p>
									</Link>								
								</li>
								<li>								
									<Link to={'/img/pdf/notice7.pdf'} target='blank' className='text-dark'> 
										<p className='mb-1'>Important notice for scholarship.</p>
									</Link>								
								</li>
								<li>								
									<Link to={'/img/pdf/notice8.pdf'} target='blank' className='text-dark'> 
										<p className='mb-1'>Notice for B. Pharm I, II, and III year students.</p>
									</Link>								
								</li>
								<li>								
									<Link to={'/img/pdf/notice9.pdf'} target='blank' className='text-dark'> 
										<p className='mb-1'>Notice for Holi Festival.</p>
									</Link>								
								</li>
								<li>								
									<Link to={'/img/pdf/notice10.pdf'} target='blank' className='text-dark'> 
										<p className='mb-1'>Notice for the students of B. Pharm last year (Session 2023-24).</p>
									</Link>								
								</li>		
								<li>								
									<Link to={'/img/pdf/notice11.pdf'} target='blank' className='text-dark'> 
										<p className='mb-1'>Notice for Good Friday dated 29th March 2024.</p>
									</Link>								
								</li>	
								</ul>
												
							</div>
						</div>
						
						<p className="mt-3 mb-0">
							<Link to={'/LatestUpdates'} className="butn fill">
								<span className="alt-font">Read More</span> <i className="fas fa-long-arrow-alt-right font-size16 sm-font-size14 margin-10px-left"></i>
							</Link>										
						</p>	
					</div>
				</div>				
			</div>
		</div>
	</section>
	
	<section className="facility-box mt-3">
		<div className="container">
			<div className="row">
				<div className="col-12 center-col margin-50px-bottom sm-margin-40px-bottom text-center">
					<h3 className="font-weight-500 font-size32 md-font-size28 sm-font-size26 xs-font-size24 section-title">About Us</h3>
					<div className="title-border"><span className="lg"></span><span className="md"></span><span className="sm"></span></div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-5">
					<div className="row">						
						<div className="col-lg-6 sm-margin-30px-bottom xs-margin-25px-bottom">
							<div className="team-block">
								<div className="team-image position-relative display-block overflow-hidden">
									<img src="img/team/chairman.png" alt="" />									
								</div>
								<div className="team-info padding-20px-all xs-padding-15px-all text-center">
									<h5 className="font-size18 md-font-size18 text-theme-color margin-5px-bottom sm-no-margin-bottom"><Link href="#!;" className="text-theme-color">A. A. NAQVI</Link></h5>
									<div className="team-position">Chairman</div>
									<p className="mt-3">
										<Link to={'/ChairpersonMessage'} className="butn fill">
											<span className="alt-font">Read More</span> <i className="fas fa-long-arrow-alt-right font-size16 sm-font-size14 margin-10px-left"></i>
										</Link>										
									</p>
								</div>
							</div>
						</div>		
						<div className="col-lg-6 sm-margin-30px-bottom xs-margin-25px-bottom">
							<div className="team-block">
								<div className="team-image position-relative display-block overflow-hidden">
									<img src="img/team/director.png" alt="" />									
								</div>
								<div className="team-info padding-20px-all xs-padding-15px-all text-center">
									<h5 className="font-size18 md-font-size18 text-theme-color margin-5px-bottom sm-no-margin-bottom"><Link href="#!;" className="text-theme-color">Dr. Zeashan Hussain</Link></h5>
									<div className="team-position">Director</div>
									<p className="mt-3">
										<Link to={'/DirectorMessage'} className="butn fill">
											<span className="alt-font">Read More</span> <i className="fas fa-long-arrow-alt-right font-size16 sm-font-size14 margin-10px-left"></i>
										</Link>
									</p>
								</div>
							</div>
						</div>				
					</div>					
				</div>
				<div className="col-md-7">
					<div className="about-bg">
						<p><b>Mahatma Gandhi Institute of Pharmacy (MGIP)</b> was established in 2008. The Institute is approved by PCI and affiliated to BTE UP and Dr. A.P.J. Abdul Kalam Technical University Lucknow.</p>
						
						<p>By choosing to study at the Mahatma Gandhi Institute of Pharmacy you are investing in an education that will help you build a successful future. </p>
						<div className='mb-3'>
						<Link href="#!;" className="butn">
											<span className="alt-font">Read More</span> <i className="fas fa-long-arrow-alt-right font-size16 sm-font-size14 margin-10px-left"></i>
										</Link>
						</div>
						<div className="d-flex justify-content-center logo-content">
							<div>
								<p className="mb-2"><Link to={'/img/pdf/PCI_Approval.pdf'} target='blank'><img src="img/logos/pci.png" alt='' /></Link></p>
								<p>Approved by PCI</p>
							</div>
							<div>
								<p className="mb-2"><Link  to={'/img/pdf/BTE_Approval.pdf'} target='blank'><img src="img/logos/bte.png" alt='' /></Link></p> 
								<p>Affiliated to BTE UP</p>
							</div>							
							
							<div>
								<p className="mb-2"><Link to={'/img/pdf/AKTU_Affiliation.pdf'} target='blank'><img src="img/logos/AKTU_Affiliation.png" alt='' /></Link></p> 
								<p>Affiliated to AKTU</p>
							</div>
						</div>
					</div>					
				</div>
			</div>
		</div>
	</section>
	
	<section className="facility-box bg-grey mt-4">
		<div className="container">
			<div className="row">
				<div className="col-8 mx-auto center-col margin-50px-bottom sm-margin-40px-bottom text-center">
					<h3 className="font-weight-500 font-size32 md-font-size28 sm-font-size26 xs-font-size24 section-title">Facilities</h3>
					<div className="title-border"><span className="lg"></span><span className="md"></span><span className="sm"></span></div>
				</div>
			</div>
			<div className="row mb-4">
				<div className="col-md-4">	
					<div class="service-2 hover-service" data-aos="fade-up">
						<img src="img/content/class_room.png" alt="..." />
						<div class="service-brief">
							<div class="elementor-flip-box-layer-overlay">
								<h4>Air Conditioned Classrooms</h4>
								<p>The class rooms are subject specific and fully air conditioned along with the overhead and LCD projector facility.</p>
								<div class="btn-inline-button">
									<Link to={'/AirConditionedClassrooms'} class="elementor-button">Read more</Link>
								</div>
							</div>
						</div>
						<div class="elementor-flip-box-layer-overlay-1">
							<h4>Air Conditioned Classrooms</h4>
						</div>
					</div>					
				</div>		
				<div className="col-md-4">
					<div class="service-2 hover-service" data-aos="fade-up">
						<img src="img/content/computer_lab.png" alt="..." />
						<div class="service-brief">
							<div class="elementor-flip-box-layer-overlay">
								<h4>Computer Lab</h4>
								<p>Computer centre has latest configuration desktop PCs on network controlled by server. Also the operating systems like Linux, Windows etc</p>
								<div class="btn-inline-button">
									<Link to={'/ComputerLab'} class="elementor-button">Read more</Link>
								</div>
							</div>
						</div>
						<div class="elementor-flip-box-layer-overlay-1">
							<h4>Computer Lab</h4>
						</div>
					</div>						
				</div>	
				<div className="col-md-4">
					<div class="service-2 hover-service" data-aos="fade-up">
						<img src="img/content/library.png" alt="..." />
						<div class="service-brief">
							<div class="elementor-flip-box-layer-overlay">
								<h4>Library</h4>
								<p>The learning and resource center (Library) is the hub of all intellectual and academics. The library has a large pool of resources viz.</p>
								<div class="btn-inline-button">
									<Link to={'/Library'} class="elementor-button">Read more</Link>
								</div>
							</div>
						</div>
						<div class="elementor-flip-box-layer-overlay-1">
							<h4>Library</h4>
						</div>
					</div>	
				</div>
			
				<div className="col-md-4">
					<div class="service-2 hover-service" data-aos="fade-up">
						<img src="img/content/auditorium.png" alt="..." />
						<div class="service-brief">
							<div class="elementor-flip-box-layer-overlay">
								<h4>Auditorium</h4>
								<p>The learning and resource center (Library) is the hub of all intellectual and academics. The library has a large pool of resources viz.</p>
								<div class="btn-inline-button">
									<Link to={'/Auditorium'} class="elementor-button">Read more</Link>
								</div>
							</div>
						</div>
						<div class="elementor-flip-box-layer-overlay-1">
							<h4>Auditorium</h4>
						</div>
					</div>
				</div>		
				<div className="col-md-4">
					<div class="service-2 hover-service" data-aos="fade-up">
						<img src="img/content/cafeteria-02.png" alt="..." />
						<div class="service-brief">
							<div class="elementor-flip-box-layer-overlay">
								<h4>Cafeteria</h4>
								<p>The institute has full-fledged cafeteria preparing and serving snacks, tea, coffee, cold drinks and various other foods.</p>
								<div class="btn-inline-button">
									<Link to={'/Cafeteria'} class="elementor-button">Read more</Link>
								</div>
							</div>
						</div>
						<div class="elementor-flip-box-layer-overlay-1">
							<h4>Cafeteria</h4>
						</div>
					</div>
				</div>	
				<div className="col-md-4">
					<div class="service-2 hover-service" data-aos="fade-up">
						<img src="img/content/transport-01.png" alt="..." />
						<div class="service-brief">
							<div class="elementor-flip-box-layer-overlay">
								<h4>Transport</h4>
								<p>We have buses and other vehicles available for transportation. Our drivers are specially trained to deal with students softly so that any undue impact can be avoided on the student's minds.</p>
								<div class="btn-inline-button">
									<Link to={'/Transport'} class="elementor-button">Read more</Link>
								</div>
							</div>
						</div>
						<div class="elementor-flip-box-layer-overlay-1">
							<h4>Transport</h4>
						</div>
					</div>					
				</div>
				<div className="col-md-4">
					<div class="service-2 hover-service" data-aos="fade-up">
						<img src="img/content/hostel.png" alt="..." />
						<div class="service-brief">
							<div class="elementor-flip-box-layer-overlay">
								<h4>Hostel</h4>
								<p>The institute provides hostel and mess, embedded with all modern facilities. Separate arrangements is available for girls and boys. 24 hours internet and Wi-Fi facilities is available in hostel.</p>
								<div class="btn-inline-button">
									<Link to={'/Hostel'} class="elementor-button">Read more</Link>
								</div>
							</div>
						</div>
						<div class="elementor-flip-box-layer-overlay-1">
							<h4>Hostel</h4>
						</div>
					</div>					
				</div>
				<div className="col-md-4">
					<div class="service-2 hover-service" data-aos="fade-up">
						<img src="img/content/security-06.png" alt="..." />
						<div class="service-brief">
							<div class="elementor-flip-box-layer-overlay">
								<h4>Campus Security</h4>
								<p>It is a prime concern for us and the safety and security of our students come first. We have security guards round the clock to meet any eventual cause of concern for the security of our students.</p>
								<div class="btn-inline-button">
									<Link to={'/Security'} class="elementor-button">Read more</Link>
								</div>
							</div>
						</div>
						<div class="elementor-flip-box-layer-overlay-1">
							<h4>Campus Security</h4>
						</div>
					</div>						
				</div>
				<div className="col-md-4">
					<div class="service-2 hover-service" data-aos="fade-up">
						<img src="img/content/wifi-campus.png" alt="..." />
						<div class="service-brief">
							<div class="elementor-flip-box-layer-overlay">
								<h4>Wi-Fi Campus</h4>
								<p></p>
								<div class="btn-inline-button">
									<Link to={'/WiFiCampus'} class="elementor-button">Read more</Link>
								</div>
							</div>
						</div>
						<div class="elementor-flip-box-layer-overlay-1">
							<h4>Wi-Fi Campus</h4>
						</div>
					</div>						
				</div>
				<div className="col-md-4">
					<div class="service-2 hover-service" data-aos="fade-up">
						<img src="img/content/sports-home.png" alt="..." />
						<div class="service-brief">
							<div class="elementor-flip-box-layer-overlay">
								<h4>Sports</h4>
								<p></p>
								<div class="btn-inline-button">
									<Link to={'/Sports'} class="elementor-button">Read more</Link>
								</div>
							</div>
						</div>
						<div class="elementor-flip-box-layer-overlay-1">
							<h4>Sports</h4>
						</div>
					</div>						
				</div>
				<div className="col-md-4">
					<div class="service-2 hover-service" data-aos="fade-up">
						<img src="img/content/emergency-medicare.png" alt="..." />
						<div class="service-brief">
							<div class="elementor-flip-box-layer-overlay">
								<h4>Emergency Medicare</h4>
								<p>For meeting any emergency, we have round the clock facilities of doctor so that health of students can be looked after in the best possible manner.</p>
								<div class="btn-inline-button">
									<Link to={'/EmergencyMedicare'} class="elementor-button">Read more</Link>
								</div>
							</div>
						</div>
						<div class="elementor-flip-box-layer-overlay-1">
							<h4>Emergency Medicare</h4>
						</div>
					</div>						
				</div>
				<div className="col-md-4">
					<div class="service-2 hover-service" data-aos="fade-up">
						<img src="img/content/herbal_garden.png" alt="..." />
						<div class="service-brief">
							<div class="elementor-flip-box-layer-overlay">
								<h4>Herbal Garden</h4>
								<p>A herbal garden is a curated space dedicated to cultivating a diverse range of herbs for various purposes.</p>
								<div class="btn-inline-button">
									<Link to={'/HerbalGarden'} class="elementor-button">Read more</Link>
								</div>
							</div>
						</div>
						<div class="elementor-flip-box-layer-overlay-1">
							<h4>Herbal Garden</h4>
						</div>
					</div>	
				</div>
			</div>
		</div>
	</section>
	
	<section className="facility-box mt-4">
		<div className="container">
			<div className="row">
				<div className="col-8 mx-auto center-col margin-50px-bottom sm-margin-40px-bottom text-center">
					<h3 className="font-weight-500 font-size32 md-font-size28 sm-font-size26 xs-font-size24 section-title">Photo Gallery</h3>
					<div className="title-border"><span className="lg"></span><span className="md"></span><span className="sm"></span></div>
				</div>
			</div>
			<div className="row mb-4">
				<div className="col-md-12">
					<div className="gallery text-center row">
                        <div className="col-lg-4 col-md-6 items presentation margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                            <div className="gallary-block">
                                <div className="position-relative">
                                    <img src="img/gallery/DSC_1802.png" alt="" />
                                    <div className="overlay-box">
                                        <Link href="img/gallery/DSC_1802.png" className="popimg"><i className="fas fa-plus"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 items courses margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                            <div className="gallary-block">
                                <div className="position-relative">
                                    <img src="img/gallery/DSC_1817.png" alt="" />
                                    <div className="overlay-box">
                                        <Link href="img/gallery/DSC_1817.png" className="popimg"><i className="fas fa-plus"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 items books margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                            <div className="gallary-block">
                                <div className="position-relative">
                                    <img src="img/gallery/DSC_1865.png" alt="" />
                                    <div className="overlay-box">
                                        <Link href="img/gallery/DSC_1865.png" className="popimg"><i className="fas fa-plus"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 items books margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                            <div className="gallary-block">
                                <div className="position-relative">
                                    <img src="img/gallery/DSC_1916.png" alt="" />
                                    <div className="overlay-box">
                                        <Link href="img/gallery/DSC_1916.png" className="popimg"><i className="fas fa-plus"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 items books margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                            <div className="gallary-block">
                                <div className="position-relative">
                                    <img src="img/gallery/DSC_1930.png" alt="" />
                                    <div className="overlay-box">
                                        <Link href="img/gallery/DSC_1930.png" className="popimg"><i className="fas fa-plus"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 items books margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                            <div className="gallary-block">
                                <div className="position-relative">
                                    <img src="img/gallery/DSC_1971.png" alt="" />
                                    <div className="overlay-box">
                                        <Link href="img/gallery/DSC_1971.png" className="popimg"><i className="fas fa-plus"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
						<div className="col-lg-4 col-md-6 items books margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                            <div className="gallary-block">
                                <div className="position-relative">
                                    <img src="img/gallery/DSC_1981.png" alt="" />
                                    <div className="overlay-box">
                                        <Link href="img/gallery/DSC_1981.png" className="popimg"><i className="fas fa-plus"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
						<div className="col-lg-4 col-md-6 items books margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                            <div className="gallary-block">
                                <div className="position-relative">
                                    <img src="img/gallery/DSC_6156.png" alt="" />
                                    <div className="overlay-box">
                                        <Link href="img/gallery/DSC_6156.png" className="popimg"><i className="fas fa-plus"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
						<div className="col-lg-4 col-md-6 items books margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                            <div className="gallary-block">
                                <div className="position-relative">
                                    <img src="img/gallery/DSC_6164.png" alt="" />
                                    <div className="overlay-box">
                                        <Link href="img/gallery/DSC_6164.png" className="popimg"><i className="fas fa-plus"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
			</div>	
			<div className="row">
				<div className="col-md-12">
					<div className="text-center pb-4 mt-3">
						<Link to={'/Photo'} className="butn fill">
							<span className="alt-font">View More</span> <i className="fas fa-long-arrow-alt-right font-size16 sm-font-size14 margin-10px-left"></i>
						</Link>
					</div>
				</div>
			</div>
		</div>
	</section>
	
	<section className="courses-area bg-light-gray">
            <div className="container">
                <div className="row">
                    <div className="col-12 center-col margin-50px-bottom md-margin-40px-bottom text-center">
                        <h3 className="font-weight-500 font-size32 md-font-size28 sm-font-size26 xs-font-size24 section-title">Our Recruiters</h3>
                        <div className="title-border"><span className="lg"></span><span className="md"></span><span className="sm"></span></div>
                    </div>
                </div>
                <div className="courses-list facility-wrapper">
                    <div id="news-ticker_3">
                        <div className="news-item3">
                             <img src="img/logos/cipla.png"  alt=''/>
                        </div>
						<div className="news-item3">
                             <img src="img/logos/piramal.png" alt='' />
                        </div>
						<div className="news-item3">
                             <img src="img/logos/emcure.png" alt='' />
                        </div>
						<div className="news-item3">
                             <img src="img/logos/sanafi.png" alt='' />
                        </div>
						<div className="news-item3">
                             <img src="img/logos/dr_reddy.png" alt='' />
                        </div>
						<div className="news-item3">
                             <img src="img/logos/jb.png"  alt=''/>
                        </div>
						<div className="news-item3">
                             <img src="img/logos/zee.png" alt='' />
                        </div>	
						<div className="news-item3">
                             <img src="img/logos/cipla.png" alt='' />
                        </div>
						<div className="news-item3">
                             <img src="img/logos/piramal.png" alt='' />
                        </div>
						<div className="news-item3">
                             <img src="img/logos/emcure.png" alt='' />
                        </div>
						<div className="news-item3">
                             <img src="img/logos/sanafi.png" alt='' />
                        </div>
						<div className="news-item3">
                             <img src="img/logos/dr_reddy.png" alt='' />
                        </div>
						<div className="news-item3">
                             <img src="img/logos/jb.png" alt='' />
                        </div>
						<div className="news-item3">
                             <img src="img/logos/zee.png" alt='' />
                        </div>	
                    </div>
                </div>
            </div>
        </section>
       

		<section className="mt-4 p-0 mb-4">
            <div className="container">
                <div className="row">					
					<div className="col-md-12">
						<iframe className="map-section" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1782.7962930124074!2d80.809915!3d26.661524!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399c0e3708eb2d0b%3A0xf8b4c470592075f5!2sMahatma%20Gandhi%20Institute%20Of%20Pharmacy!5e0!3m2!1sen!2sus!4v1709663535996!5m2!1sen!2sus" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
					</div>
				</div>
			</div>
	</section>

	{isOpen && (<div class="modal d-block z-10 bd-example-modal-lg" tabindex="-1">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header p-0">					
					<button type="button" onClick={closePopup} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body p-0">
					 <img src='img/bg/offer.png' alt='' />
				</div>
			
			</div>
		</div>
	</div>  )}
    </>
  )
}

export default Home
